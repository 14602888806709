import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

function Card(props) {
  const { url, title, children, link, alt } = props
  return (
    <div className="col-md-6 col-lg-4 mt-5">
      <div
        className="card h-100 shadow aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <div className="card-image">
          <Image
            fluid={{
              ...url,
              aspectRatio: 16 / 9,
            }}
            alt={alt}
            className="banner__image"
          />
        </div>
        <div className="card-body">
          <h6 className="card-title">{title}</h6>
          {children}
        </div>
        <div className="card-footer">
          <Link to={link} className="btn btn-outline-primary">
            Read more
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card
