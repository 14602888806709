import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/about/service-centre-building.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="banner row no-gutters">
      <div className="col-md-6 order-md-2">
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="Mitre 10 Mega Masterton Service Centre Building"
          className="banner__image"
        />
      </div>
      <div className="col-md-6 order-md-1 bg-dark text-white d-flex align-items-center">
        <div className="row w-100 no-gutters justify-content-center p-4">
          <div className="col-sm-10 col-lg-9">
            <h2 className="display-4 mb-3">Keep your machine running.</h2>
            <p className="lead mb-4">
              We service and repair almost anything with a small petrol engine.
              We stock parts for most makes and models of lawnmowers, chainsaws,
              trimmers, and water blasters.
            </p>
            <a href="/contact/" className="mb-2 btn btn-lg btn-outline-primary">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
