import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Card from "../components/Card"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Service Center | Keep your machine running</title>
        <meta
          name="description"
          content="We service and repair almost anything with a small petrol engine. For example: leaf blowers, hedge trimmers, shredders, log splitters."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <Banner />

        <section>
          <div className="container">
            <h2 className="mb-3">Services</h2>
            <div className="row mt-n3">
              <Card
                title="Lawnmowers"
                link="/lawnmowers/"
                url={data["allImageSharp"]["edges"][6]["node"]["fluid"]}
                alt="Lawnmower"
              >
                <p className="card-text">
                  At the Masterton Mitre10 Mega Service Centre we repair and
                  maintain most types of lawnmowers. Regularly servicing your
                  lawnmower will extend its life.
                </p>
              </Card>

              <Card
                url={data["allImageSharp"]["edges"][8]["node"]["fluid"]}
                alt="Chainsaw"
                title="Chainsaws"
                link="/chainsaws"
              >
                <p className="card-text">
                  Keeping your chainsaw clean and regularly serviced will make
                  it safer to use, but it will also prolong the chainsaw's life.
                </p>
              </Card>

              <Card
                url={data["allImageSharp"]["edges"][10]["node"]["fluid"]}
                alt="Water Blaster"
                title="Water Blasters"
                link="/water-blasters"
              >
                <p className="card-text">
                  We repair water blasters quickly and affordably. We stock a
                  large range of parts and accessories to get your water blaster
                  running smoothly in no time.
                </p>
              </Card>

              <Card
                url={data["allImageSharp"]["edges"][7]["node"]["fluid"]}
                alt="Line Trimmer"
                title="Line Trimmers"
                link="/line-trimmers"
              >
                <p className="card-text">
                  Is your line trimmer hard to start, runs badly, or the cutting
                  line won’t feed? Our expert mechanic has seen it all.
                </p>
              </Card>

              <Card
                title="Generators & Compressors"
                link="/generators-compressors"
                url={data["allImageSharp"]["edges"][5]["node"]["fluid"]}
                alt="Generator"
              >
                <p className="card-text">
                  We service and repair all types of generators and compressors.
                  Call us now to chat with our team of experts.
                </p>
              </Card>

              <Card
                url={data["allImageSharp"]["edges"][9]["node"]["fluid"]}
                alt="Hedge Trimmer"
                title="Other machinery"
                link="/other-machinery"
              >
                <p className="card-text">
                  We service and repair almost anything with a small petrol
                  engine. For example: leaf blowers, hedge trimmers, shredders,
                  log splitters.
                </p>
              </Card>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
